import { gql } from '@apollo/client/core'
import { GetStaticPaths, GetStaticProps, NextPage } from 'next'
import dynamic from 'next/dynamic'
import React, { PropsWithChildren } from 'react'
import { client } from '../apollo/client'
import { Layout } from '../components/layout/layout'
import { REVALIDATION_FREQUENCY } from '../constants/revalidation'
import { GlobalProps, withLayoutProps } from '../globals/layout'
import { PAGE_QUERY } from '../templates/page'
import { PageQuery, PageQueryVariables } from '../templates/page/__generated__'
import { nonNull } from '../utils/nonNull'
import { pageQuery } from '../utils/pageQuery'
import { NonFcpPageQuery, NonFcpPageQueryVariables, RetourQuery, RetourQueryVariables } from './__generated__/[...slug]'
// import { RETOUR_QUERY } from '../utils/redirect'

export const RETOUR_QUERY = gql`
  query RetourQuery($uri: String!) {
    retourResolveRedirect(uri: $uri) {
      redirectDestUrl
    }
  }
`

export type PageProps = GlobalProps & {
  data: PageQuery
}

const Fcp = dynamic(() => import('../templates/page').then(mod => mod.Page))
const ProductListing = dynamic(() => import('../templates/productListing').then(mod => mod.ProductListing))
const ProductDetail = dynamic(() => import('../templates/productDetails').then(mod => mod.ProductDetails))

const Page: NextPage<PageProps> = ({ globals, data }) => {
  let Template: React.ComponentType<PropsWithChildren<typeof data>> = React.Fragment

  switch (data.entry && data.entry.__typename) {
  case 'products_pages_product_fcp_Entry':
    Template = Fcp
    break
  case 'products_pages_product_listing_Entry':
    Template = ProductListing
    break
  case 'products_pages_product_detail_Entry':
    Template = ProductDetail
    break
  }

  return (
    <Layout globals={globals}>
      <Template { ...data } />
    </Layout>
  )
}

export default Page

export const getStaticProps: GetStaticProps<Record<string, unknown>, { slug: string[] }> = async (context) => {
  const slug = context.params?.slug


  if (!slug) {
    throw new Error('Missing page slug')
  }

  const retour = await client.query<RetourQuery, RetourQueryVariables>({
    query: RETOUR_QUERY,
    variables: {
      uri: `/${slug.join('/')}`,
    },
  })

  const { retourResolveRedirect } = retour?.data
  
  if (retourResolveRedirect?.redirectDestUrl !== null && typeof retourResolveRedirect?.redirectDestUrl === 'string') {
    return {
      redirect: {
        destination: retourResolveRedirect?.redirectDestUrl,
        permanent: false,
      },
    }
  }

  const { data } = await pageQuery<PageQuery, PageQueryVariables>({
    query: PAGE_QUERY,
    variables: {
      uri: slug.join('/'),
    },
  }, context)

  return withLayoutProps({
    path: `/${slug.join('/')}`,
  }, data.entry ? {
    props: { data },
    revalidate: REVALIDATION_FREQUENCY,
  } : {
    notFound: true,
  })
}

const NON_FCP_PAGE_QUERY = gql`
  query NonFcpPageQuery {
    entries(section: "products_pages", type: ["product_listing", "product_detail"]) {
      uri
    }
  }
`

export const getStaticPaths: GetStaticPaths = async () => {
  const { data } = await client.query<NonFcpPageQuery, NonFcpPageQueryVariables>({
    query: NON_FCP_PAGE_QUERY,
  })

  const fcpPaths = ['/about-us']

  return ({
    paths: nonNull(data.entries?.map(entry => entry?.uri ? `/${entry.uri}` : undefined) ?? []).concat(fcpPaths),
    fallback: 'blocking',
  })
}
